import "./Mode.css";
import { RiSunFill } from "react-icons/ri";
import { AiFillGithub } from "react-icons/ai";

export default function Mode({ handleModeChange }) {
  return (
    <section className=" container-mode">
      <div className="mode-left white-text">
        <ul>
          {           }
          <li className="left-source">
            <AiFillGithub className="source-icon" />
            <a class="gray-text" href="https://github.com/YaAlex3">GitHub</a>
          </li>
        </ul>
      </div>
      <button onClick={handleModeChange} className="container-icon">
        <RiSunFill className="mode-icon" />
      </button>
    </section>
  );
}

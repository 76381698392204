import "./About.css";
import iconImage from "../../images/logo.jpg";

export const About = () => {
  return (
    <section className="container container-about">
      <div className="about-left">
        <span className="purple-text">
          ✋Oleksandr K.{" "}
          <span className="purple-smoke-text">- Introduction</span>{" "}
        </span>
        <p className="gray-text">
          A passionate self-taught developer and a music producer.
          Making responsive and beautiful web solutions.
          Experienced in Python/JavaScript and many respective frameworks such as Flask and Django, React and Vue.
          Using my skills in C/C++, Java and Rust in my sweet personal projects.
          {" "}
        </p>
        <div className="tech-stack">
          <span className="purple-text">Tech Stack</span>
          <ul className="tech-list gray-text">
            <li>Web Development</li>
            <li>Embedded Linux Development</li>
            <li>Backend Development</li>
            <li>System Administration / Support</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

import "./Header.css";
import TypeAnimation from 'react-type-animation';
export default function Header() {
  return (
    <section className="header">
      <header>
        <h1  className="header-title">
          <TypeAnimation
            cursor={true}
            sequence={['', 500, 'Hello.']}
            repeat="3"
        />
        </h1>
        <div className="header-title-box">
          Developer based in Ukraine! 🇺🇦
        </div>
      </header>
    </section>
  );
}
